import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NavBar from './NavBar';
import Home from './Home';
import About from './About';

const App = () => {
  return (
    <>
      <NavBar />

      <Switch>
        <Route path="/">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
      </Switch>
    </>
  );
};

export default App;