import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

const NavBar = () => {

  // return (
  //   <nav className="navbar navbar-expand-lg navbar-light bg-light">
  //     <div className="custom-navbar-image">
  //       <a className="navbar-brand" href="/">
  //         <img src="/images/Original.svg" width="218" height="30" alt="NSAR-Tech Ltd" />
  //       </a>
  //     </div>
  //     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
  //       <span className="navbar-toggler-icon"></span>
  //     </button>
  //     <div className="collapse navbar-collapse" id="navbarSupportedContent">
  //       <ul className="navbar-nav mr-auto nav nav-pills nav-fill">
  //         {about}
  //         {contact}
  //       </ul>
  //     </div>
  //   </nav>
  // );
  const isAboutActive = window.location.pathname === 'about' ? true : false;
  const isContactActive = window.location.pathname === 'contact' ? 'active' : '';

  return (
    <Navbar bg="light" expand="lg" fixed="top" variant="light">
      <Navbar.Brand href="/">
        <img
          src="/images/Original.svg"
          width="218"
          height="30"
          className="d-inline-block align-top"
          alt="NSAR-Tech Ltd"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="navbar-nav mr-auto nav nav-pills nav-fill">
          <Nav.Link href="/about" active={isAboutActive}>About</Nav.Link>
          <Nav.Link href="/contact" active={isContactActive}>Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;