import _ from 'lodash'
import process from 'process'

import base from './base';
import prod from './prod';
import local from './local';

const configs = {
  prod,
  local,
};

const extendConfig = (config) => {
  return _.merge(base, config);
};

const getConfig = (env = 'local') => {
  const config = configs[env];

  if (!config) {
    throw new Error(`Config not found for environment: '${env}'`);
  }

  return extendConfig(config);
};

export default getConfig(process.env.NODE_ENV);