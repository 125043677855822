import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import FullStory from 'react-fullstory';

import * as serviceWorker from './serviceWorker';

import App from './components/App';
import config from './config';

import './custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const app = (
<Fragment>
  <FullStory org={config.fullStory.orgId}/>
  <Router>
    <Route exact path='/' component={App} />
  </Router>
</Fragment>);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
