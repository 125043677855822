import React from 'react';

const Home = () => {
  return (
    <div className="container-fluid h-100">

      <div className="row primary-panel-row">
        <div className="col h-100">
          <div className="d-flex align-items-center justify-content-center h-100">
            <img width="400" height="55" src="/images/Original.svg" alt="NSAR-Tech Ltd" className="align-self-center" />
          </div>
        </div>
      </div>

      <div className="row secondary-panel-row">

        <div className="col h-100 bottom-panel-left d-flex align-items-center justify-content-center">
          About
        </div>

        <div className="col h-100 bottom-panel-right">
          <div className="col h-100 bottom-panel-right d-flex align-items-center justify-content-center">
            Our Services
          </div>
        </div>

      </div>

    </div>
  );
};

export default Home;